import { request as axios } from '@/util/request';
import { PaginationData } from '@/api/operation/customer';
const getUrl = require('baimahu/url-with-param');
const API = {
  add_position: '/boss/operation/advertisement',
  category: '/boss/product/category',
  zone: '/boss/operation/zone',
  ware_data: '/boss/product/spu',
}



// 请求参数数据接口
interface SendData {
  category_id: number | string;
  category_name: string;
  image_url: string;
  skip_type: number;
  zone_id: number;
  product_id: number;
  is_promotion: number;
}
// 新增广告位
export async function addPosition(send_data: SendData) {
  return await axios.post(API.add_position, send_data);
}


// 分类数据接口
export interface Category {
  create_time: number;
  id: number;
  level: number;
  name: string;
  parent: any[];
  parent_id: number;
  sort: number;
  status: number;
  update_time: number;
}

// 初始化分类数据
export function initCategory(remote?: Category[]): Category[] {
  remote = remote || {} as Category[];
  return remote;
}
// 获取分类信息
export async function getCategoryData() {
  return await axios.get(API.category);
}




// 专区数据接口
export interface Zone {
  banner_image_url: string;
  box_count: number;
  colour_number: string;
  create_time: number;
  detail_page_title: string;
  home_image_plus_url: string;
  home_image_url: string;
  id: number;
  sort: number;
  status: number;
  style: number;
  subtitle: string;
  title: string;
  update_time: number;
}
// 初始化专区数据
export function initZoneData(remote?: PaginationData<Zone>): PaginationData<Zone> {
  remote = remote || {} as PaginationData<Zone>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    total: remote.total || 0,
    last: remote.last || 0,
  }
}
// 获取专区数据
export async function getZoneWare(current: number = 1, title: string = '', limit: number) {
  const url = getUrl(API.zone, {
    current,
    title,
    limit
  });
  return await axios.get(url);
}


// 请求参数数据接口
interface QueryParams {
  id?: string;
  title?: string;
  category?: number;
  status?: number;
  current?: number;
}
// 商品数据接口
export interface WareData {
  category: CategoryData;
  category_id: number;
  config_limit_time: number;
  create_time: number;
  detail_image_url: string;
  goods_parameter: string;
  goods_services: string;
  id: number;
  is_recommend: number;
  is_show: number;
  parameter: [];
  price: number;
  putaway_time: number;
  putaway_type: number;
  relevance: number;
  relevance_id: number;
  sales: number;
  sort: number;
  stock: number;
  subtitle: string;
  title: string;
  update_time: number;
  video_url: string;
  viewpager_url: string;
  warehouse: [];
}
// 分类数据数据接口
interface CategoryData {
  create_time: number;
  id: number;
  name: string;
  level: number;
  parent_id: number;
  sort: number;
  status: number;
  update_time: number;
}
// 初始化商品数据
export function initWareData(remote?: PaginationData<WareData>): PaginationData<WareData> {
  remote = remote || {} as PaginationData<WareData>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    last: remote.last || 0,
    total: remote.total || 0,
  }
}
// 获取商品数据
export async function wareData(query_params?: QueryParams) {
  const url = getUrl(API.ware_data, query_params);
  return await axios.get(url);
}


// 广告位数据接口
export interface AdvPosition {
  category_id: number;
  category_name: string;
  create_time: number;
  id: number;
  image_url: string;
  is_promotion: number;
  product_id: number;
  product_info: any;
  skip_type: number;
  update_time: number;
  zone_id: any;
}
// 获取广告位列表
export async function getPositionList() {
  return await axios.get(API.add_position);
}


// 删除广告位
export async function deleteAdvPosition(id: number) {
  return await axios.delete(`${API.add_position}/${id}`);
}


// 获取广告位的详情
export async function getAdvPositionDetail(id: number) {
  return await axios.get(`${API.add_position}/${id}`);
}

// 编辑广告位
export async function editPosition(send_data: SendData, id: number) {
  return await axios.put(`${API.add_position}/${id}`, send_data);
}