
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import CustomModal from '@/component/custom-modal.vue';
import {
  getPositionList,
  AdvPosition,
  deleteAdvPosition,
} from '@/api/operation/advertising-position';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
@Component({
  name: 'AdvertisingPosition',
  components: {
    CustomModal,
  },
})
export default class AdvertisingPosition extends Vue {
  setGlobalFunction = useGlobalFunction().setGlobalFunction; //更新数据
  adv_position_list: AdvPosition[] = [];
  is_loading: boolean = false;
  table_data: any[] = [];
  is_show_img_modal: boolean = false;
  is_show_delete_modal: boolean = false;
  img_index: number = -1;
  delete_id: number = -1;
  // 查看大图
  viewImage(index: number) {
    this.is_show_img_modal = true;
    this.img_index = index;
  }
  // 点击删除按钮
  clickDeleteBtn(item: any) {
    this.is_show_delete_modal = true;
    this.delete_id = item.id;
  }
  // 删除广告位
  @changeLoading(['is_loading'])
  async deleteAdvPosition() {
    const res = await deleteAdvPosition(this.delete_id);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('删除成功');
    this.is_show_delete_modal = false;
    await this.getAdvPosition();
    console.log(res);
  }
  // 去编辑页面
  goEditPage(item: any) {
    this.$router.push(`/operation/add-advertising-position?id=${item.id}`);
  }
  // 获取广告位列表
  @changeLoading(['is_loading'])
  async getAdvPosition() {
    const res = await getPositionList();
    this.adv_position_list = [];
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.adv_position_list = res.data;
  }
  @changeLoading(['is_loading'])
  async created() {
    this.setGlobalFunction({ getAdvPosition: this.getAdvPosition }) //更新数据
    this.getAdvPosition();
  }
}
